import DynamicComponent from "./DynamicComponent";
import { Helmet } from "react-helmet";
import React from "react";
import SbEditable from "storyblok-react";
import rewriteSlug from "../utils/rewriteSlug";
import useStoryblok from "../utils/storyblok";

const Page = ({ space, location, slug, story }) => {
  // Run hook to get story dynamically
  story = useStoryblok(story, location);

  const blok = story.content;

  const content =
    blok.body &&
    blok.body.map(childBlok => (
      <DynamicComponent blok={childBlok} key={childBlok._uid} />
    ));

  const navigation = blok.navigation?.content && (
    <DynamicComponent blok={blok.navigation.content} />
  );

  const footer = blok.footer?.content && (
    <DynamicComponent blok={blok.footer.content} />
  );

  let language = location.pathname.split("/")[1].replace("/", "");
  let activeLanguage = ["ar", "en"].includes(language) ? language : "en";

  const meta = (
    <Helmet>
      <title>{blok.seo_title || "BrightSign"}</title>
      <meta name="description" content={blok.seo_description} />
      <meta name="keywords" content={blok.seo_keywords} />
      <meta name="author" content="BrightSign Technology Limited" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#eb5177" />
      <meta name="theme-color" content="#ffffff" />

      <meta property="og:title" content={blok.seo_title || "BrightSign"} />
      <meta property="og:description" content={blok.seo_description} />
      <meta
        property="og:url"
        content={`https://www.brightsignglove.com/${rewriteSlug(slug)}`}
      />
      <meta property="og:description" content={blok.seo_description} />
      <meta property="og:locale" content={activeLanguage} />
      <meta property="og:type" content="website" />

      <meta property="twitter:card" content="website" />
      <meta
        property="twitter:site"
        content={activeLanguage === "ar" ? "brightsignksa" : "brightsignglove"}
      />
      <meta propterty="description" content={blok.seo_description} />
      <meta property="twitter:title" content={blok.seo_title} />

      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/round-flag-icons/css/round-flag-icons.min.css"
      />

      <link
        rel="canonical"
        href={`https://www.brightsignglove.com/${rewriteSlug(slug)}`}
      />

      {space?.language_codes.map((language_code, language_code_idx) => {
        const language_regex = RegExp(
          `(${space.language_codes.filter(code => code !== "").join("|")})/`
        );
        const language_code_string =
          language_code === "" || language_code === "en"
            ? ""
            : `${language_code}/`;

        let full_slug = slug.replace("home", "");
        full_slug = full_slug.replace(language_regex, "");
        full_slug = `${language_code_string}${full_slug}`;

        return (
          <link
            rel="alternate"
            href={`${space.domain}${full_slug}`}
            hrefLang={!language_code.length ? "x-default" : language_code}
            key={language_code_idx}
          />
        );
      })}
    </Helmet>
  );

  return (
    <SbEditable content={blok} key={blok._uid} story={story}>
      {meta}
      {navigation}
      {content}
      {footer}
    </SbEditable>
  );
};

export default Page;
