import React from "react";
import SbEditable from "storyblok-react";
import rewriteUrl from "../utils/rewriteUrl";

const Button = ({ blok }) => {
  let url = blok.url ? rewriteUrl(blok.url) : "";

  if (blok.url.anchor) {
    url = `${url}#${blok.url.anchor}`;
  }

  switch (blok.variant || "primary") {
    case "white":
      return (
        <SbEditable content={blok} key={blok._uid}>
          <a className="font-bold text-black btn-white" href={url}>
            {blok.icon && <span aria-hidden className={`me-4 ${blok.icon}`} />}
            {blok.text}
          </a>
        </SbEditable>
      );
    case "primary":
      return (
        <SbEditable content={blok} key={blok._uid}>
          <a className="btn-gradient" href={url}>
            {blok.icon && <span aria-hidden className={`me-4 ${blok.icon}`} />}
            {blok.text}
          </a>
        </SbEditable>
      );

    case "secondary":
      return (
        <SbEditable content={blok} key={blok._uid}>
          <a className="btn-pale" href={url}>
            {blok.icon && <span aria-hidden className={`me-4 ${blok.icon}`} />}
            {blok.text}
          </a>
        </SbEditable>
      );

    case "rounded":
      return (
        <SbEditable content={blok} key={blok._uid}>
          <a
            className="inline-block px-10 py-5 text-sm font-semibold transition duration-500 bg-white rounded-full shadow-md font-display hover:shadow-xl"
            href={url}
          >
            <div className="flex flex-row items-center space-s-4 text-gradient">
              <span aria-hidden className={`text-gradient ${blok.icon}`}></span>
              <span className="text-gradient">{blok.text}</span>
            </div>
          </a>
        </SbEditable>
      );

    case "icon":
      return (
        <SbEditable content={blok} key={blok._uid}>
          <a
            className="flex flex-row items-center justify-center text-sm font-semibold transition duration-500 transform hover:scale-110"
            href={url}
            aria-label={blok.text}
          >
            <span
              aria-hidden
              className={`text-gradient ${blok.icon} text-6xl`}
            ></span>
          </a>
        </SbEditable>
      );

    default:
      return (
        <SbEditable content={blok} key={blok._uid}>
          <a className="btn-gradient" href={url}>
            {blok.icon && <span aria-hidden className={`me-4 ${blok.icon}`} />}
            {blok.text}
          </a>
        </SbEditable>
      );
  }
};

export default Button;
