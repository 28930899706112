import React from "react";
import SbEditable from "storyblok-react";

const Youtube = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="aspect-w-16 aspect-h-9">
        <iframe
          className="rounded-xl"
          src={blok.source_url.cached_url}
          title={blok.title}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </div>
    </SbEditable>
  );
};

export default Youtube;
