import React from "react";
import RichText from "./RichText";
import SbEditable from "storyblok-react";
import Section from "./Section";

const App = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Section color={"gradient"} id={blok.id}>
        <div className="w-full max-w-xl mx-auto mb-12 text-center">
          <h2>{blok.title}</h2>
          <RichText document={blok.content} className="text-white" />
        </div>
        <div className="grid w-full max-w-xs grid-cols-1 gap-8 mx-auto md:max-w-lg md:grid-cols-2">
          <a
            className="flex flex-row items-center justify-center p-4 border border-white rounded-md space-s-4 app-store-card"
            href={blok.apple_button_url.cached_url}
            rel="noreferrer"
          >
            <span className="text-6xl fab fa-apple" aria-hidden></span>
            <div className="flex flex-col">
              <h3 className="mb-1 text-xl">{blok.apple_button_title}</h3>
              <p className="mb-0 text-white">{blok.apple_button_subtitle}</p>
            </div>
          </a>
          <a
            className="flex flex-row items-center justify-center p-4 border border-white rounded-md space-s-4 app-store-card"
            href={blok.android_button_url.cached_url}
            rel="noreferrer"
          >
            <span className="text-5xl fab fa-android" aria-hidden></span>
            <div className="flex flex-col">
              <h3 className="mb-1 text-xl">{blok.android_button_title}</h3>
              <p className="mb-0 text-white">{blok.android_button_subtitle}</p>
            </div>
          </a>
        </div>
      </Section>
    </SbEditable>
  );
};

export default App;
