import { Helmet } from "react-helmet";
import React from "react";

export default function Layout({ children, location, lang }) {
  let { pathname } = location;
  let language = pathname.split("/")[1].replace("/", "");
  let activeLanguage = ["ar", "en"].includes(language) ? language : "en";
  let activeDirection = ["ar"].includes(activeLanguage) ? "rtl" : "ltr";

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: activeLanguage,
          dir: activeDirection,
        }}
      />
      <div className="">
        <main>{children}</main>
      </div>
    </>
  );
}
