/**
 * Configure your Gatsby site with this file.
 *
 * See: https://www.gatsbyjs.com/docs/gatsby-config/
 */
const rewriteSlug = require("./src/utils/rewriteSlug");

module.exports = {
  /* Your site config here */
  siteMetadata: {
    siteUrl: process.env.URL || `https://www.brightsignglove.com/`,
    title: "BrightSign",
    author: "BrightSign Technology Ltd",
  },
  plugins: [
    `gatsby-plugin-preact`,
    `gatsby-plugin-image`,
    `gatsby-plugin-sharp`,
    `gatsby-transformer-sharp`,
    "gatsby-plugin-postcss",
    "gatsby-plugin-react-helmet",
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: "jQwSpmLj1YF21LxoKzDxAwtt",
        homeSlug: "home",
        version: process.env.NODE_ENV === "production" ? "published" : "draft",
        resolveRelations: [
          "page.navigation",
          "page.footer",
          "blog_post.navigation",
          "blog_post.footer",
          "blog_post.author",
        ],
        resolveLinks: "url",
      },
    },
    {
      resolve: `gatsby-plugin-google-fonts-with-attributes`,
      options: {
        fonts: [
          `Open Sans:600,700`,
          `Roboto:300,400,400i,700`,
          `Cairo:300,400,400i,600,700`,
        ],
        display: "swap",
        attributes: {
          rel: "stylesheet preload prefetch",
        },
      },
    },
    {
      resolve: `gatsby-plugin-i18n`,
      options: {
        langKeyDefault: "en",
        langKeyForNull: "en",
        prefixDefault: false,
        useLangKeyLayout: false,
      },
    },
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        // You can add multiple tracking ids and a pageview event will be fired for all of them.
        trackingIds: [
          "G-Z94B7YSHW4", // Google Analytics / GA
          "AW-10775206148", // Adwords
        ],
        // This object gets passed directly to the gtag config command
        // This config will be shared across all trackingIds
        gtagConfig: {
          anonymize_ip: false,
          cookie_expires: 0,
        },
        // This object is used for configuration specific to this plugin
        pluginConfig: {
          // Puts tracking script in the head instead of the body
          head: true,
        },
      },
    },
    {
      resolve: "gatsby-plugin-tidio-chat",
      options: {
        tidioKey: "2xxva2p8gwep2vjsrmdbxbtok9twc8ws",
        enableDuringDevelop: false,
      },
    },
    {
      resolve: "gatsby-plugin-sitemap",
      options: {
        query: `
          {
            site {
              siteMetadata {
                siteUrl
              }
            }

            allSitePage {
              nodes {
                path
              }
            }

            allStoryblokEntry(
              filter: { field_component: { in: ["page", "blog_post"] } }
            ) {
              edges {
                node {
                  id
                  published_at
                  full_slug
                }
              }
            }
          }
        `,
        resolvePages: ({ allSitePage, allStoryblokEntry }) => {
          console.log(allSitePage);
          const sbNodeMap = allStoryblokEntry.edges.reduce((acc, node) => {
            const slug = "/" + rewriteSlug(node.full_slug);
            acc[slug] = node;

            return acc;
          }, {});

          return allSitePage.nodes.map(page => ({
            ...page,
            ...sbNodeMap[page.path],
          }));
        },
        serialize: ({ path, published_at }) => ({
          url: path,
          updated_at: published_at,
          changefreq: "weekly",
        }),
        excludes: [`/404`],
        createLinkInHead: true,
      },
    },
    {
      resolve: `gatsby-plugin-facebook-pixel`,
      options: {
        pixelId: "277421521048014",
      },
    },
  ],
};
