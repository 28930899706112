import React from "react";
import SbEditable from "storyblok-react";
import Section from "./Section";

const DownloadBox = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Section color={blok.bg || "grey"} id={blok.id}>
        <a
          className={`flex flex-row items-center p-8 transition duration-1000 bg-white rounded-lg hover:shadow-xl space-s-2 ${
            blok.bg !== "grey" && "shadow-lg"
          }`}
          target="_blank"
          rel="noreferrer"
          href={blok.file.filename}
        >
          <div className="flex-grow space-y-2">
            <h2 className="mb-0 text-xl">{blok.title}</h2>
            <p>{blok.subtitle}</p>
          </div>
          <span className="text-6xl fas fa-file-download text-gradient"></span>
        </a>
      </Section>
    </SbEditable>
  );
};

export default DownloadBox;
