import React from "react";
import RichText from "./RichText";
import SbEditable from "storyblok-react";
import Section from "./Section";

const Prose = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Section color={blok.bg} id={blok.id}>
        <RichText document={blok.content}></RichText>
      </Section>
    </SbEditable>
  );
};

export default Prose;
