import DynamicComponent from "./DynamicComponent";
import React from "react";
import SbEditable from "storyblok-react";
import Section from "./Section";

const ItemRow4 = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Section color={blok.bg} id={blok.id}>
        <div className="w-full max-w-xl mx-auto mb-12 text-center">
          <h2>{blok.title}</h2>
          <p className="mb-10 font-normal text-gray-500">{blok.paragraph}</p>
          {blok.buttons.map(button => (
            <DynamicComponent blok={button} key={button._uid} />
          ))}
        </div>
        <div className="grid grid-cols-1 gap-8 mb-16 md:grid-cols-2 lg:grid-cols-4">
          <div className="px-8 pt-12 pb-8 text-center transition duration-1000 rounded-md shadow-xl hover:shadow-2xl">
            <span
              aria-hidden
              className={`${blok.item1_icon} text-3xl text-white bg-gradient rounded p-4 mb-8 w-16 h-16`}
            ></span>
            <h3 className="text-xl">{blok.item1_title}</h3>
            <p className="text-gray-500">{blok.item1_text}</p>
          </div>
          <div className="px-8 pt-12 pb-8 text-center transition duration-1000 rounded-md shadow-xl hover:shadow-2xl">
            <span
              aria-hidden
              className={`${blok.item2_icon} text-3xl text-white bg-gradient rounded p-4 mb-8 w-16 h-16`}
            ></span>
            <h3 className="text-xl">{blok.item2_title}</h3>
            <p className="text-gray-500">{blok.item2_text}</p>
          </div>
          <div className="px-8 pt-12 pb-8 text-center transition duration-1000 rounded-md shadow-xl hover:shadow-2xl">
            <span
              aria-hidden
              className={`${blok.item3_icon} text-3xl text-white bg-gradient rounded p-4 mb-8 w-16 h-16`}
            ></span>
            <h3 className="text-xl">{blok.item3_title}</h3>
            <p className="text-gray-500">{blok.item3_text}</p>
          </div>
          <div className="px-8 pt-12 pb-8 text-center transition duration-1000 rounded-md shadow-xl hover:shadow-2xl">
            <span
              aria-hidden
              className={`${blok.item4_icon} text-3xl text-white bg-gradient rounded p-4 mb-8 w-16 h-16`}
            ></span>
            <h3 className="text-xl">{blok.item4_title}</h3>
            <p className="text-gray-500">{blok.item4_text}</p>
          </div>
        </div>
      </Section>
    </SbEditable>
  );
};

export default ItemRow4;
