import Picture from "./Picture";
import React from "react";
import SbEditable from "storyblok-react";

const BrandItem = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="flex flex-row items-center justify-center w-full transition duration-500 transform hover:scale-105">
        <Picture
          src={blok.image.filename}
          width={0}
          height={64}
          title={blok.image.title}
          alt={blok.image.alt}
          className="max-h-16"
        />
      </div>
    </SbEditable>
  );
};

export default BrandItem;
