import Picture from "./Picture";
import React from "react";
import RichText from "./RichText";
import SbEditable from "storyblok-react";

const TeamMember = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="transition duration-1000 bg-white rounded-lg team-card hover:shadow-xl">
        <div className="flex flex-col items-center p-8 text-center">
          <Picture
            src={blok.headshot.filename}
            width={96}
            height={96}
            alt={blok.headshot.alt}
            title={blok.headshot.title}
            className="w-24 h-24 mb-8 rounded-full"
          />
          <h3 className="mb-0 text-xl text-gradient">{blok.name}</h3>
          <p className="mb-8 text-gray-500">{blok.job_title}</p>
          <div className="text-gray-500 ">
            <RichText document={blok.bio} />
          </div>
        </div>
      </div>
    </SbEditable>
  );
};

export default TeamMember;
