import LinkListItem from "./LinkListItem";
import React from "react";
import SbEditable from "storyblok-react";

const LinkList = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="space-y-4">
        <h3>{blok.title}</h3>
        {blok.links && (
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {blok.links.map(link => (
              <LinkListItem blok={link} key={link._uid} />
            ))}
          </div>
        )}
        {!blok.links?.length && blok.empty_text && (
          <p className="py-4 ms-8">{blok.empty_text}</p>
        )}
      </div>
    </SbEditable>
  );
};

export default LinkList;
