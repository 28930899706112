import React from "react";
import { components } from "react-select";

const { Option } = components;

const DropdownLinkOption = props => (
  <Option {...props}>
    <div className="flex flex-row items-center space-s-2">
      <i
        aria-hidden
        className={`text-xl w-8 h-8 ${props.data.icon} ${
          props.data.icon.includes("round-flag-icon") || `text-gradient`
        }`}
      ></i>
      <span>{props.data.label}</span>
    </div>
  </Option>
);

export default DropdownLinkOption;
