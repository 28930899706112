import React from "react";
import rewriteImageUrl from "../utils/rewriteImageUrl";

const Picture = ({ src, width = 0, height = 0, alt, title, ...props }) => {
  return (
    <picture>
      <source
        srcSet={`${rewriteImageUrl(
          src,
          width,
          height,
          "webp"
        )}, ${rewriteImageUrl(src, width * 2, height * 2, "webp")} 2x`}
        type="image/webp"
      />
      <source
        srcSet={`${rewriteImageUrl(src, width, height)}, ${rewriteImageUrl(
          src,
          width * 2,
          height * 2
        )} 2x`}
      />
      <img
        src={rewriteImageUrl(src, width, height)}
        srcSet={`${rewriteImageUrl(src, width, height)}, ${rewriteImageUrl(
          src,
          width * 2,
          height * 2
        )} 2x`}
        title={title}
        alt={alt}
        width={width || null}
        height={height || null}
        {...props}
      />
    </picture>
  );
};

export default Picture;
