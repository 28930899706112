import React, { useState } from "react";

import DropdownLinkOption from "./DropdownLinkOption";
import SbEditable from "storyblok-react";
import Select from "react-select";
import rewriteUrl from "../utils/rewriteUrl";

const DropdownLinkSection = ({ blok }) => {
  const [url, setUrl] = useState("");
  const items = blok.dropdown_items;

  const options = items.map(item => ({
    label: item.label,
    value: item.url ? rewriteUrl(item.url) : "",
    icon: item.icon,
  }));

  const dropdownStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused && "#f3f4f6",
      color: "black",
      "&:hover": {
        backgroundColor: state.isFocused ? "#f3f4f6" : "#f9fafb",
      },
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: "0.375rem",
      padding: "0.5rem",
      backgroundColor: "white",
      boxShadow: "none",
      transitionProperty: "border-color",
      transitionDuration: 0.3,
      transitionTimingFunction: "cubic-bezier(.4,0.2,.1)",
      borderColor: state.isFocused ? "#9ca3af" : "#e5e7eb",
      "&:hover": {
        borderColor: state.isFocused ? "#9ca3af" : "#d1d5db",
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      borderRadius: "0.375rem",
      boxShadow:
        "0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04)",
    }),
  };

  const handleSubmit = e => {
    if (url) window.location.assign(url);
    e.preventDefault();
  };

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="container px-8 pb-24 mx-auto -mt-24 text-center">
        <div className="w-full max-w-xl p-8 m-4 mx-auto transition duration-1000 bg-white shadow-xl hover:shadow-2xl rounded-xl">
          <form className="flex flex-col space-y-6" onSubmit={handleSubmit}>
            <Select
              name="url"
              options={options}
              components={{ Option: DropdownLinkOption }}
              styles={dropdownStyles}
              onChange={e => setUrl(e.value)}
            ></Select>
            <button className="btn-gradient" type="submit">
              {blok.button_text}
            </button>
          </form>
        </div>
      </div>
    </SbEditable>
  );
};

export default DropdownLinkSection;
