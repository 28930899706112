import React from "react";
import SbEditable from "storyblok-react";
import rewriteUrl from "../utils/rewriteUrl";

const AddressCard = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <a
        className="transition duration-1000 bg-white team-card rounded-xl hover:shadow-xl"
        href={rewriteUrl(blok.link)}
      >
        <div className="flex flex-col items-center p-8 text-center">
          <i
            aria-hidden
            className={`text-6xl w-24 h-24 m-8 ${blok.icon} ${
              blok.icon.includes("round-flag-icon") || `text-gradient`
            }`}
          ></i>
          <h4 className="mb-0 text-black">{blok.title}</h4>
          <p className="mb-8 text-gray-500">{blok.subtitle}</p>
        </div>
      </a>
    </SbEditable>
  );
};

export default AddressCard;
