import DynamicComponent from "./DynamicComponent";
import { Helmet } from "react-helmet";
import React from "react";
import SbEditable from "storyblok-react";
import rewriteImageUrl from "../utils/rewriteImageUrl";

const Banner = ({ blok }) => {
  const bgUrl = rewriteImageUrl(blok.background.filename);
  return (
    <>
      <Helmet>
        <link rel="preload" as="image" href={bgUrl} />
      </Helmet>
      <SbEditable content={blok} key={blok._uid}>
        <div
          className="bg-center bg-cover"
          style={{ backgroundImage: `url('${bgUrl}')` }}
        >
          <div className="container px-8 mx-auto text-white py-36">
            <div className="w-full max-w-xl mx-auto text-center">
              <h1>{blok.title}</h1>
              <p className="mb-8 text-lg font-light text-white">
                {blok.subtitle}
              </p>
              {blok.buttons &&
                blok.buttons.map(button => <DynamicComponent blok={button} />)}
            </div>
          </div>
        </div>
      </SbEditable>
    </>
  );
};

export default Banner;
