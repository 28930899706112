import DynamicComponent from "./DynamicComponent";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import Picture from "./Picture";
import React from "react";
import RichText from "./RichText";
import SbEditable from "storyblok-react";
import rewriteImageUrl from "../utils/rewriteImageUrl";
import useStoryblok from "../utils/storyblok";

const BlogPage = ({ space, location, slug, story }) => {
  // Run hook to get story dynamically
  story = useStoryblok(story, location);

  const blok = story.content;

  const navigation = blok.navigation?.content && (
    <DynamicComponent blok={blok.navigation.content} />
  );

  const footer = blok.footer?.content && (
    <DynamicComponent blok={blok.footer.content} />
  );

  const meta = (
    <Helmet>
      <title>{blok.seo_title || "BrightSign"}</title>
      <meta name="description" content={blok.seo_description} />
      <meta name="keywords" content={blok.seo_keywords} />
      <meta name="author" content="BrightSign Technology Limited" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#eb5177" />
      <meta name="theme-color" content="#ffffff" />

      <meta property="og:title" content={blok.seo_title || "BrightSign"} />
      <meta property="og:description" content={blok.seo_description} />
      <meta
        property="og:image"
        content={rewriteImageUrl(blok.feature_image.filename)}
      />
      <meta property="og:type" content="article" />

      {space?.language_codes.map((language_code, language_code_idx) => {
        const language_regex = RegExp(`(${space.language_codes.join("|")})/`);
        const full_slug = `${
          language_code === "" || language_code === "en"
            ? ""
            : `${language_code}/`
        }${slug.replace("home", "").replace(language_regex, "")}`;
        return (
          <link
            rel="alternate"
            href={`${space.domain}${full_slug}`}
            hrefLang={!language_code.length ? "x-default" : language_code}
            key={language_code_idx}
          />
        );
      })}
    </Helmet>
  );

  const author = blok.author.content;

  return (
    <SbEditable content={blok} key={blok._uid} story={story}>
      {meta}
      {navigation}
      <div className="w-full bg-gray-100">
        <div
          id="feature_image"
          className="w-full bg-center bg-cover h-96"
          style={{
            backgroundImage: `url('${rewriteImageUrl(
              blok.feature_image.filename
            )}')`,
          }}
        ></div>
        <div className="container max-w-4xl px-8 pb-16 mx-auto space-y-8">
          <div className="px-12 pt-12 pb-16 mx-auto -mt-24 space-y-8 bg-white shadow-xl rounded-xl">
            <h1 className="mb-0 text-4xl md:text-6xl">{blok.title}</h1>
            <div className="flex flex-row items-center space-s-4">
              <div className="flex flex-row items-center justify-start space-s-4">
                <Picture
                  src={rewriteImageUrl(author.image.filename)}
                  alt={`Photo of ${author.name}`}
                  title={author.name}
                  width={50}
                  height={50}
                ></Picture>
                <div className="flex flex-col">
                  <span className="font-normal">{author.name}</span>
                  {author.title && (
                    <span className="text-sm text-gray-500">
                      {author.title}
                    </span>
                  )}
                </div>
              </div>
              {blok.date && (
                <>
                  <div className="hidden w-px h-12 bg-gray-200 md:block"></div>
                  <div className="flex-col hidden text-sm italic text-gray-500 md:flex">
                    <span className="text-xs">Published on:</span>
                    <Moment date={blok.date} format="Do MMMM, YYYY"></Moment>
                  </div>
                </>
              )}
            </div>
            <div className="h-2 rounded-full w-36 bg-gradient"></div>
            <RichText document={blok.prose}></RichText>
          </div>
        </div>
      </div>
      {footer}
    </SbEditable>
  );
};

export default BlogPage;
