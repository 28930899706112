import DynamicComponent from "./DynamicComponent";
import React from "react";
import RichText from "./RichText";
import SbEditable from "storyblok-react";
import Section from "./Section";

const Address = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Section color={blok.bg} id={blok.id}>
        <div className="w-full max-w-xl mx-auto mb-12 text-center">
          <h2>{blok.title}</h2>
          <RichText document={blok.content} />
        </div>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          {blok.address_cards &&
            blok.address_cards.map(address_card => (
              <DynamicComponent blok={address_card} key={address_card._uid} />
            ))}
        </div>
      </Section>
    </SbEditable>
  );
};

export default Address;
