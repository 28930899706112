import DynamicComponent from "./DynamicComponent";
import React from "react";
import SbEditable from "storyblok-react";
import Section from "./Section";

const LinkSection = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Section color={blok.bg} id={blok.id}>
        <div className="flex flex-col space-y-16">
          {blok.link_lists &&
            blok.link_lists.map(link_list => (
              <DynamicComponent blok={link_list} key={link_list._uid} />
            ))}
        </div>
      </Section>
    </SbEditable>
  );
};

export default LinkSection;
