import React from "react";
import SbEditable from "storyblok-react";
import Section from "./Section";

const PostTitle = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Section color={blok.bg} id={blok.id}>
        <div className="container mx-auto space-y-8">
          <div className="space-y-3">
            <h1 className="mb-0">{blok.title}</h1>
            <p className="text-2xl ms-1">{blok.subtitle}</p>
          </div>
          <div className="h-2 rounded-full w-36 bg-gradient"></div>
        </div>
      </Section>
    </SbEditable>
  );
};

export default PostTitle;
