import React from "react";
import rewriteUrl from "../utils/rewriteUrl";

const FooterNavlink = ({ blok }) => {
  let url = blok.link ? rewriteUrl(blok.link) : "";

  if (blok.link.anchor) {
    url = `${url}#${blok.link.anchor}`;
  }

  return (
    <a className="transition duration-300 hover:text-gray-300" href={url}>
      {blok.icon && <span className={`${blok.icon} me-1`} aria-hidden />}
      {blok.text}
      {blok.badge && (
        <span className="p-1 px-2 text-xs font-bold text-white uppercase rounded ms-2 bg-gradient">
          {blok.badge}
        </span>
      )}
    </a>
  );
};

export default FooterNavlink;
