import Picture from "./Picture";
import React from "react";
import SbEditable from "storyblok-react";
import rewriteUrl from "../utils/rewriteUrl";

const LinkListItem = ({ blok }) => {
  let url = rewriteUrl(blok.link);

  return (
    <SbEditable content={blok} key={blok._uid}>
      <a
        className="flex flex-col transition duration-1000 bg-white rounded-xl hover:shadow-xl"
        href={url}
      >
        {blok.thumbnail && (
          <Picture
            src={blok.thumbnail.filename}
            alt={blok.thumbnail.alt}
            title={blok.thumbnail.title}
            height={400}
            className="w-full rounded-t-xl"
          />
        )}
        <div className="flex flex-row items-center p-4 space-s-2">
          <p className="flex-grow text-lg font-bold">{blok.text}</p>
          <span className="text-gradient fas fa-link" aria-hidden></span>
        </div>
      </a>
    </SbEditable>
  );
};

export default LinkListItem;
