import DynamicComponent from "./DynamicComponent";
import React from "react";
import SbEditable from "storyblok-react";

const ScrolldownList = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="collapsible">
        <div className="py-8 space-y-4">
          <h3 className="ps-8">{blok.title}</h3>
          {blok.scrolldowns &&
            blok.scrolldowns.map(scrolldown => (
              <DynamicComponent blok={scrolldown} key={scrolldown._uid} />
            ))}
          {!blok.scrolldowns?.length && blok.empty_text && (
            <p className="py-4 ms-8">{blok.empty_text}</p>
          )}
        </div>
      </div>
    </SbEditable>
  );
};

export default ScrolldownList;
