import DynamicComponent from "./DynamicComponent";
import Picture from "./Picture";
import React from "react";
import RichText from "./RichText";
import SbEditable from "storyblok-react";
import Section from "./Section";

const ImageRight = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Section color={blok.bg} id={blok.id}>
        <div className="grid grid-cols-1 gap-24 lg:grid-cols-2">
          <div className="flex flex-col items-start justify-center">
            <h2>{blok.title}</h2>
            <div className="mb-10 text-gray-500">
              <RichText document={blok.content}></RichText>
            </div>
            {blok.buttons.map(button => (
              <DynamicComponent blok={button} key={button._uid} />
            ))}
          </div>
          {blok.image.filename && (
            <div className="flex flex-row items-center justify-center lg:justify-end">
              <Picture
                src={blok.image.filename}
                alt={blok.image.alt}
                title={blok.image.title}
                width={500}
                height={500}
              />
            </div>
          )}
        </div>
      </Section>
    </SbEditable>
  );
};

export default ImageRight;
