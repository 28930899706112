import Address from "./Address";
import AddressCard from "./AddressCard";
import App from "./App";
import Banner from "./Banner";
import BrandGrid from "./BrandGrid";
import BrandItem from "./BrandItem";
import Button from "./Button";
import ColumnForm from "./ColumnForm";
import ColumnFormField from "./ColumnFormField";
import Contact from "./Contact";
import DownloadBox from "./DownloadBox";
import DropdownLinkSection from "./DropdownLinkSection";
import Feature from "./Feature";
import Footer from "./Footer";
import IconButtonRow from "./IconButtonRow";
import ImageLeft from "./ImageLeft";
import ImageRight from "./ImageRight";
import ItemRow3 from "./ItemRow3";
import ItemRow4 from "./ItemRow4";
import LinkList from "./LinkList";
import LinkSection from "./LinkSection";
import Navbar from "./Navbar";
import Navlink from "./Navlink";
import Newsletter from "./Newsletter";
import Paragraph from "./Paragraph";
import Placeholder from "./Placeholder";
import PostTitle from "./PostTitle";
import PriceItem from "./PriceItem";
import Pricing from "./Pricing";
import Prose from "./Prose";
import React from "react";
import Scrolldown from "./Scrolldown";
import ScrolldownList from "./ScrolldownList";
import ScrolldownSection from "./ScrolldownSection";
import Team from "./Team";
import TeamMember from "./TeamMember";
import Youtube from "./Youtube";

const Components = {
  feature: Feature,
  button: Button,
  banner: Banner,
  item_row_4: ItemRow4,
  item_row_3: ItemRow3,
  image_left: ImageLeft,
  image_right: ImageRight,
  pricing: Pricing,
  price_item: PriceItem,
  paragraph: Paragraph,
  team: Team,
  team_member: TeamMember,
  app: App,
  navigation: Navbar,
  nav_link: Navlink,
  newsletter: Newsletter,
  contact: Contact,
  address_card: AddressCard,
  address: Address,
  scrolldown_section: ScrolldownSection,
  scrolldown_list: ScrolldownList,
  scrolldown: Scrolldown,
  footer: Footer,
  brand_item: BrandItem,
  brand_grid: BrandGrid,
  post_title: PostTitle,
  prose: Prose,
  youtube: Youtube,
  download_box: DownloadBox,
  link_list: LinkList,
  link_section: LinkSection,
  icon_button_row: IconButtonRow,
  dropdown_link_section: DropdownLinkSection,
  column_form: ColumnForm,
  column_form_field: ColumnFormField,
};

const DynamicComponent = ({ blok }) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component];
    return <Component blok={blok} key={blok._uid} />;
  }

  return blok.component ? <Placeholder componentName={blok.component} /> : null;
};

export default DynamicComponent;
