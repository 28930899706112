import DynamicComponent from "./DynamicComponent";
import React from "react";
import RichText from "./RichText";
import SbEditable from "storyblok-react";
import Section from "./Section";

const Team = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Section color={blok.bg} id={blok.id}>
        <div className="w-full max-w-xl mx-auto mb-12 text-center">
          <h2>{blok.title}</h2>
          <div className="text-gray-500 ">
            <RichText document={blok.content}></RichText>
          </div>
        </div>
        <div
          className={`grid grid-cols-1 gap-16 ${
            blok.team_cards?.length % 3 === 0
              ? `md:grid-cols-3`
              : `md:grid-cols-2`
          }`}
        >
          {blok.team_cards &&
            blok.team_cards.map(team_member => (
              <DynamicComponent blok={team_member} key={team_member._uid} />
            ))}
        </div>
      </Section>
    </SbEditable>
  );
};

export default Team;
