import DynamicComponent from "./DynamicComponent";
import React from "react";
import RichText from "./RichText";
import SbEditable from "storyblok-react";
import Section from "./Section";

const BrandGrid = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Section color={blok.bg} id={blok.id}>
        <div className="w-full max-w-xl mx-auto mb-12 text-center">
          <h2>{blok.title}</h2>
          <div
            className={`mb-8 space-y-4 ${
              blok.bg === "gradient" ? "text-white" : "text-gray-500"
            }`}
          >
            <RichText document={blok.content}></RichText>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
          {blok.brands &&
            blok.brands.map(brand => (
              <DynamicComponent blok={brand} key={brand._uid} />
            ))}
        </div>
      </Section>
    </SbEditable>
  );
};

export default BrandGrid;
