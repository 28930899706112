function rewriteImageUrl(url, width = 0, height = 0, format) {
  if (!url) return url;

  let newImageUrl = url;

  let replacement = `//img2.storyblok.com`;

  if (width !== 0 && height !== 0) {
    replacement = `${replacement}/fit-in/${width}x${height}`;
  } else if (width !== 0 || height !== 0) {
    replacement = `${replacement}/${width}x${height}`;
  }

  if (format) replacement = `${replacement}/filters:format(${format})`;

  newImageUrl = newImageUrl.replace("//a.storyblok.com", replacement);

  return newImageUrl;
}

// es5 export because of import into gatsby-node.js
module.exports = rewriteImageUrl;
