import DynamicComponent from "./DynamicComponent";
import React from "react";
import RichText from "./RichText";
import SbEditable from "storyblok-react";
import Section from "./Section";

const Newsletter = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Section color={blok.bg} id={blok.id}>
        <div className="w-full max-w-xl mx-auto mb-12 text-center">
          <h2>{blok.title}</h2>
          <div className="mb-10 text-gray-500">
            <RichText document={blok.content} />
          </div>
          <form
            className="grid grid-cols-1 gap-4 md:grid-cols-3"
            id="workshopSignUp"
            method="POST"
            name={blok.form_name}
            action={blok.form_success.cached_url}
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input
              className="p-4 text-sm transition duration-300 border border-gray-200 rounded-md outline-none hover:border-gray-300 focus:border-gray-400 md:col-span-2"
              placeholder={blok.placeholder}
              type="email"
              name="email"
              aria-label={blok.placeholder}
              required="required"
            />
            <input className="hidden" type="text" name="locale" />
            <input
              className="hidden"
              type="text"
              name="listId"
              value="5"
              readOnly
            />
            <input type="hidden" name="form-name" value={blok.form_name} />
            <p className="hidden">
              <input name="bot-field" />
            </p>
            <button className="btn-gradient" type="submit">
              {blok.button_text}
            </button>
          </form>
          {blok.buttons && (
            <div className="flex flex-row justify-center mt-12 space-s-8">
              {blok.buttons.map(button => (
                <DynamicComponent blok={button} key={button._uid} />
              ))}
            </div>
          )}
        </div>
      </Section>
    </SbEditable>
  );
};

export default Newsletter;
