import React from "react";
import SbEditable from "storyblok-react";
import rewriteUrl from "../utils/rewriteUrl";

const Navlink = ({ blok }) => {
  let url = blok.link ? rewriteUrl(blok.link) : "";

  return (
    <SbEditable content={blok} key={blok._uid}>
      <li className="text-xs text-gray-500 transition duration-500 hover:text-black">
        <a href={url}>
          {blok.icon && <span className={`${blok.icon} me-1`} aria-hidden />}
          {blok.text}
        </a>
      </li>
    </SbEditable>
  );
};

export default Navlink;
