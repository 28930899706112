import DynamicComponent from "./DynamicComponent";
import React from "react";
import RichText from "./RichText";
import SbEditable from "storyblok-react";

const PriceItem = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="transition duration-1000 bg-white rounded hover:shadow-xl">
        <div className="flex flex-row items-center justify-between p-8 border-b-2 border-gray-100">
          <div className="flex flex-col">
            <h3 className="mb-2 text-xl">{blok.title}</h3>
            <p className="mb-0 text-gray-500">{blok.subtitle}</p>
          </div>
          <p
            className="mb-0 text-4xl font-semibold text-black font-display"
            dir="ltr"
          >
            {blok.price}
          </p>
        </div>
        <div className="flex flex-col items-center p-8 space-y-4 text-center">
          <RichText document={blok.properties} />
          {blok.buttons.map(button => (
            <DynamicComponent blok={button} key={button._uid} />
          ))}
        </div>
      </div>
    </SbEditable>
  );
};

export default PriceItem;
