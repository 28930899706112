import DynamicComponent from "./DynamicComponent";
import React from "react";
import SbEditable from "storyblok-react";
import Section from "./Section";

const IconButtonRow = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Section color={blok.bg} id={blok.id}>
        {blok.buttons && (
          <div className="flex flex-row justify-center w-full space-s-8">
            {blok.buttons.map(button => (
              <DynamicComponent blok={button} key={button._uid} />
            ))}
          </div>
        )}
      </Section>
    </SbEditable>
  );
};

export default IconButtonRow;
