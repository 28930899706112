import React from "react";
import SbEditable from "storyblok-react";

const ColumnFormField = ({ blok }) => {
  if (blok.field_type === "text") {
    return (
      <SbEditable content={blok} key={blok._uid}>
        <input
          className="input"
          placeholder={`${blok.placeholder}${blok.required ? " *" : ""}`}
          type="text"
          name={blok.name}
          aria-label={blok.placeholder}
          required={blok.required}
        />
      </SbEditable>
    );
  } else if (blok.field_type === "phone") {
    return (
      <SbEditable content={blok} key={blok._uid}>
        <input
          className="input"
          placeholder={`${blok.placeholder}${blok.required ? " *" : ""}`}
          type="phone"
          name={blok.name}
          aria-label={blok.placeholder}
          required={blok.required}
        />
      </SbEditable>
    );
  } else if (blok.field_type === "email") {
    return (
      <SbEditable content={blok} key={blok._uid}>
        <input
          className="input"
          placeholder={`${blok.placeholder}${blok.required ? " *" : ""}`}
          type="email"
          name={blok.name}
          aria-label={blok.placeholder}
          required={blok.required}
        />
      </SbEditable>
    );
  } else if (blok.field_type === "textarea") {
    return (
      <SbEditable content={blok} key={blok._uid}>
        <textarea
          className="input h-36"
          placeholder={`${blok.placeholder}${blok.required ? " *" : ""}`}
          name={blok.name}
          aria-label={blok.placeholder}
          required={blok.required}
        ></textarea>
      </SbEditable>
    );
  } else if (blok.field_type === "checkbox") {
    return (
      <SbEditable content={blok} key={blok._uid}>
        <div className="flex items-center px-2 flew-row space-s-2">
          <input
            type="checkbox"
            name={blok.name}
            required={blok.required}
          ></input>
          <p>
            {blok.placeholder}
            {blok.required ? " *" : ""}
          </p>
        </div>
      </SbEditable>
    );
  } else {
    return <SbEditable content={blok} key={blok._uid}></SbEditable>;
  }
};

export default ColumnFormField;
