import React from "react";

const Section = ({ children, color, id }) => {
  switch (color) {
    case "grey":
      return (
        <div>
          {id && <div className="relative -top-24" id={id}></div>}
          <div className="bg-gray-50">
            <div className="container px-8 py-16 mx-auto text-black">
              {children}
            </div>
          </div>
        </div>
      );

    case "gradient":
      return (
        <div>
          {id && <div className="relative -top-24" id={id}></div>}
          <div className="bg-gradient">
            <div className="container px-8 py-16 mx-auto text-white">
              {children}
            </div>
          </div>
        </div>
      );

    default:
      return (
        <div>
          {id && <div className="relative -top-24" id={id}></div>}
          <div className="bg-white">
            <div className="container px-8 py-16 mx-auto text-black">
              {children}
            </div>
          </div>
        </div>
      );
  }
};

export default Section;
