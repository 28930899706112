import React, { useState } from "react";

import RichText from "./RichText";
import SbEditable from "storyblok-react";
import { SlideDown } from "react-slidedown";

const Scrolldown = ({ blok }) => {
  const [open, setOpen] = useState(false);

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="w-full px-8 transition duration-500 rounded-md shadow-lg text-start hover:shadow-xl">
        <button
          className={`collapsible-header w-full text-start ${open && `open`}`}
          onClick={() => setOpen(!open)}
        >
          <div className="flex flex-row items-center py-8 space-s-4">
            <span
              aria-hidden
              className="transition duration-300 rtl:hidden collapser-icon-right text-gradient fas fa-chevron-right"
            />
            <span
              aria-hidden
              className="transition duration-300 ltr:hidden collapser-icon-left text-gradient fas fa-chevron-left"
            />
            <span className="text-lg font-semibold font-display">
              {blok.title}
            </span>
            {blok.badge && (
              <span className="p-1 px-2 text-sm font-bold text-white uppercase rounded bg-gradient">
                {blok.badge}
              </span>
            )}
          </div>
        </button>
        <SlideDown
          closed={!open}
          className="overflow-y-hidden transition duration-300"
        >
          <div className="pb-8">
            <RichText document={blok.content} />
          </div>
        </SlideDown>
      </div>
    </SbEditable>
  );
};

export default Scrolldown;
