import DynamicComponent from "./DynamicComponent";
import React from "react";
import RichText from "./RichText";
import SbEditable from "storyblok-react";
import Section from "./Section";

const ScrolldownSection = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Section color={blok.bg} id={blok.id}>
        <div className="w-full max-w-xl mx-auto mb-12 text-center">
          <h2>{blok.title}</h2>
          <RichText document={blok.content} />
        </div>

        <div className="flex flex-col space-y-4">
          {blok.scrolldown_lists &&
            blok.scrolldown_lists.map(scrolldown_list => (
              <DynamicComponent
                blok={scrolldown_list}
                key={scrolldown_list._uid}
              />
            ))}
        </div>
      </Section>
    </SbEditable>
  );
};

export default ScrolldownSection;
