import React from "react";
import SbEditable from "storyblok-react";
import { navigate } from "gatsby";
import rewriteUrl from "../utils/rewriteUrl";

const Contact = ({ blok }) => {
  const handleSubmit = event => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    const hubspotutk = document.cookie.replace(
      /(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );

    console.log(hubspotutk);

    formData.set("hubspotutk", hubspotutk);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => navigate(rewriteUrl(blok.form_success, true)))
      .catch(error => alert(error));
  };

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="container px-8 pb-24 mx-auto -mt-24 text-center">
        <div className="w-full max-w-2xl p-8 m-4 mx-auto transition duration-1000 bg-white shadow-xl hover:shadow-2xl rounded-xl">
          <form
            id="contactForm"
            name={blok.form_name}
            method="post"
            onSubmit={handleSubmit}
            action={rewriteUrl(blok.form_success, true)}
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <div className="grid grid-cols-1 gap-4 mb-8 md:grid-cols-3">
              <input
                className="input"
                placeholder={blok.name_placeholder}
                type="text"
                name="name"
                aria-label={blok.name_placeholder}
                required="required"
              />
              <input
                className="input"
                placeholder={blok.email_placeholder}
                type="email"
                name="email"
                aria-label={blok.email_placeholder}
                required="required"
              />
              <input
                className="input"
                placeholder={blok.subject_placeholder}
                type="text"
                name="subject"
                aria-label={blok.subject_placeholder}
                required="required"
              />
              <textarea
                className="input md:col-span-3 h-36"
                placeholder={blok.message_placeholder}
                name="message"
                aria-label={blok.message_placeholder}
                required="required"
              ></textarea>
              <input type="hidden" name="form-name" value={blok.form_name} />
              <input type="hidden" name="hubspotutk" value={""} />
              <p className="hidden">
                <input name="bot-field" />
              </p>
            </div>
            <div className="flex flex-row justify-end">
              <button className="btn-gradient" type="submit">
                {blok.button_text}
              </button>
            </div>
          </form>
        </div>
      </div>
    </SbEditable>
  );
};

export default Contact;
