import DynamicComponent from "./DynamicComponent";
import Picture from "./Picture";
import React from "react";
import SbEditable from "storyblok-react";

const Feature = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div
        className="bg-center bg-cover"
        style={{ backgroundImage: `url('${blok.background.filename}')` }}
      >
        <div className="container px-8 mx-auto text-white">
          <div className="grid items-center grid-cols-1 gap-8 lg:grid-cols-2">
            <div className="my-32">
              <h1>{blok.title}</h1>
              <p className="mb-8 text-lg font-light text-white">
                {blok.content}
              </p>
              {blok.buttons.map(button => (
                <DynamicComponent blok={button} key={button._uid} />
              ))}
            </div>
            <div className="hidden lg:block justify-self-end">
              <Picture
                src={blok.image.filename}
                alt={blok.image.alt}
                title={blok.image.title}
                className="hidden w-full max-w-lg lg:block"
                width={512}
              />
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  );
};

export default Feature;
