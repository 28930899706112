import React, { useEffect, useState } from "react";

import DynamicComponent from "./DynamicComponent";
import Picture from "./Picture";
import SbEditable from "storyblok-react";
import { SlideDown } from "react-slidedown";
import rewriteUrl from "../utils/rewriteUrl";

const Navbar = ({ blok }) => {
  const [navOpen, setNavOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  let homeUrl = blok.home_url ? rewriteUrl(blok.home_url) : "";

  useEffect(() => {
    const onScroll = scrollEvent =>
      window.scrollY > 0 ? setScrolled(true) : setScrolled(false);

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <SbEditable content={blok} key={blok._uid}>
      <header
        className={`sticky top-0 z-10 transition-shadow duration-300 bg-white ${
          scrolled && `shadow-lg`
        }`}
      >
        <div className="container mx-auto">
          <nav className="flex flex-row items-center justify-between px-8 py-8">
            <a
              className="flex-shrink-0"
              href={homeUrl}
              aria-label={blok.logo.title}
            >
              <Picture
                src={blok.logo.filename}
                width={0}
                height={32}
                alt={blok.logo.alt}
                title={blok.logo.title}
              />
            </a>
            <ul className="flex-row hidden text-xs uppercase space-s-8 lg:flex font-display">
              {blok.header_links.map(header_link => (
                <DynamicComponent blok={header_link} key={header_link._uid} />
              ))}
            </ul>
            <button
              className={`lg:hidden ${navOpen && `open`}`}
              id="hamburger"
              aria-expanded={navOpen}
              aria-label="Toggle Navigation"
              onClick={() => {
                setNavOpen(!navOpen);
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </nav>
          <SlideDown
            closed={!navOpen}
            className="overflow-y-hidden transition duration-300"
            transitionOnAppear={false}
          >
            {navOpen && (
              <ul
                className="flex flex-col items-center pb-8 space-y-4 text-sm uppercase lg:hidden font-display"
                id="mobileMenu"
              >
                {blok.header_links.map(header_link => (
                  <DynamicComponent blok={header_link} key={header_link._uid} />
                ))}
              </ul>
            )}
          </SlideDown>
        </div>
      </header>
    </SbEditable>
  );
};

export default Navbar;
