import DynamicComponent from "./DynamicComponent";
import React from "react";
import SbEditable from "storyblok-react";

const ColumnForm = ({ blok }) => {
  console.log(blok);
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="container px-8 pb-24 mx-auto -mt-24 text-center">
        <div className="w-full max-w-xl p-8 m-4 mx-auto transition duration-1000 bg-white shadow-xl hover:shadow-2xl rounded-xl">
          <form
            id={blok.form_name}
            method="POST"
            name={blok.form_name}
            action={blok.form_success.cached_url}
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <div className="grid grid-cols-1 gap-4">
              {blok.fields.map(field => (
                <DynamicComponent
                  blok={field}
                  key={field._uid}
                ></DynamicComponent>
              ))}
              <input type="hidden" name="form-name" value={blok.form_name} />
              <p className="hidden">
                <input name="bot-field" />
              </p>
              <button className="btn-gradient" type="submit">
                {blok.button_text}
              </button>
            </div>
          </form>
        </div>
      </div>
    </SbEditable>
  );
};

export default ColumnForm;
