import FooterNavlink from "./FooterNavlink";
import React from "react";
import SbEditable from "storyblok-react";

const Footer = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <footer className="bg-gray-800">
        <div className="container px-8 pt-16 pb-8 mx-auto space-y-8 text-gray-400">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <div className="col-span-2 space-y-4">
              <h4 className="flex flex-row items-center space-s-2">
                <span className="fas fa-link" aria-hidden></span>
                <span>{blok.links_title}</span>
              </h4>
              <div className="grid grid-flow-row grid-cols-1 gap-2 md:grid-flow-col md:grid-rows-5 md:grid-cols-none">
                {blok.navigation &&
                  blok.navigation.map(navItem => (
                    <FooterNavlink blok={navItem} key={navItem._uid} />
                  ))}
              </div>
            </div>
            <div className="space-y-4">
              <h4 className="flex flex-row items-center space-s-2">
                <span className="fas fa-globe" aria-hidden></span>
                <span>{blok.languages_title}</span>
              </h4>
              <div className="grid grid-cols-1 gap-2 grid-flow-cols">
                {blok.languages &&
                  blok.languages.map(languageItem => (
                    <FooterNavlink
                      blok={languageItem}
                      key={languageItem._uid}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <p className="mb-0 text-gray-400">{blok.copyright}</p>
            <div className="flex flex-row text-white space-s-2">
              {blok.socials &&
                blok.socials.map(socialItem => (
                  <a
                    className="flex items-center justify-center w-8 h-8 text-gray-300 transition duration-300 bg-gray-700 rounded hover:bg-gray-600 hover:text-gray-100"
                    href={socialItem.link.cached_url}
                    target="_blank"
                    aria-label={socialItem.link.text}
                    rel="noreferrer"
                    key={socialItem._uid}
                  >
                    <span
                      className={`text-lg ${socialItem.icon}`}
                      aria-hidden
                    ></span>
                  </a>
                ))}
            </div>
          </div>
        </div>
        <div className="container px-8 py-8 mx-auto text-white border-t border-gray-700">
          <div className="flex items-center justify-center flew-row">
            <p className="mb-0 text-center text-gray-400">{blok.legal}</p>
          </div>
        </div>
      </footer>
    </SbEditable>
  );
};

export default Footer;
