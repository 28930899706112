import DynamicComponent from "./DynamicComponent";
import React from "react";
import RichText from "./RichText";
import SbEditable from "storyblok-react";
import Section from "./Section";

const Pricing = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Section color={blok.bg} id={blok.id}>
        <div className="flex flex-col w-full max-w-3xl mx-auto my-12 text-center space-s-8 md:flex-row">
          <div className="flex flex-col flex-1 p-8 space-y-6 transition duration-1000 bg-white shadow-lg md:w-1/2 rounded-2xl hover:shadow-xl">
            <div className="flex flex-col">
              <h3 className="mb-2 text-xl">{blok.included_title}</h3>
              <RichText document={blok.included_intro} />
            </div>
            <RichText document={blok.included_list} />
            {blok.included_buttons &&
              blok.included_buttons.map(button => (
                <DynamicComponent blok={button} key={button._uid} />
              ))}
          </div>
          <div className="flex flex-col justify-start flex-1 p-8 space-y-4 md:w-1/2">
            <h3 className="text-3xl text-start">{blok.affordability_title}</h3>
            <RichText document={blok.affordability} className="text-start" />
            {blok.affordability_buttons &&
              blok.affordability_buttons.map(button => (
                <DynamicComponent blok={button} key={button._uid} />
              ))}
          </div>

          {/* <h2>{blok.title}</h2>
          <div className="mb-10 text-gray-500">
            <RichText document={blok.content}></RichText>
          </div> */}
        </div>

        {/* <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          {blok.price_items &&
            blok.price_items.map(price_item => (
              <DynamicComponent blok={price_item} key={price_item._uid} />
            ))}
        </div> */}
      </Section>
    </SbEditable>
  );
};

export default Pricing;
